.md-button {
  vertical-align: middle;
}

.full-width {
  width: 100%;
}

.centered {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.prewrap {
  white-space: pre-wrap;
}

.see-more {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

.inverse .see-more a {
  color: white;
}

.dollar-prefix.md-input-has-value::before,
.dollar-prefix.md-input-focused::before {
  content: '$';
  position: absolute;
  top: 0.34em;
  opacity: 0.6;
}

.dollar-prefix input {
  padding-left: 0.8em;
}

.ajax-errors {
  background: $C_error;
  color: white;
  padding: 1em;
  border-radius: 4px;
  width: 80%;
  margin: 0 auto 0;
  font-weight: bold;
  margin-bottom: 1em;

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  li {

  }
}
