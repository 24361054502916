main.admin {
  @extend .container;
  padding: 1em;

  .heading {
    @include span-columns(1);
  }

  .heading h1 {
    margin-top: 0;
  }

  .admin-actions {
    @include span-columns(3);
    @include omega;
    text-align: right;
    position: relative;

    md-input-container {
      position: relative;
      bottom: -0.76em;
      width: 260px;
      margin: 0;
      text-align: left;
    }
  }
}

#admin-nav-drawer {
  @extend %drawer-nav;
}

.question-dialog {
  padding: 24px;
  width: 800px;
  max-width: 100%;

  h2 {
    margin-top: 0;
  }
}

p.narrow-dialog {
  max-width: 500px;
}

.assessment-question {
  h3 small {
    color: #397cbf;

    a {
      cursor: pointer;
    }
  }
}

.md-dialog-container {
  z-index: 1100 !important;
}

.md-select-menu-container {
  z-index: 1200 !important;
}

#mce-modal-block {
  z-index: 900 !important;
}

.mce-window {
  z-index: 1000 !important;
}

.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}

.md-toolbar-tools .md-button {
  min-width: 40px;
  min-height: 24px;
  font-size: 12px;
  margin-left: 4px;
  margin-right: 4px;
}

.md-toolbar-tools .material-icons {
  font-size: 18px;
}
