table.admin-table {
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;

  th {
    border-bottom: 1px solid shade($base-border-color, 25%);
    font-weight: 600;
    padding: $small-spacing 0;
    text-align: left;
  }

  td {
    border-bottom: $base-border;
    padding: $small-spacing 0;
  }

  tr,
  td,
  th {
    vertical-align: middle;
  }

  thead {
    font-weight: bold;
  }

  .table-actions {
    text-align: right;
  }

  thead .md-icon-button,
  .table-actions .md-icon-button {
    opacity: 0.3;
    @include transition(opacity 400ms);

    &:hover {
      opacity: 1;
    }
  }

  thead .md-icon-button md-icon {
    font-size: 1.5em;
  }

  td:not(:last-child) {
    padding-right: 1em;
  }
}

.pagination {
  margin: 2em 0;

  .page-info {
    text-align: center;
    padding-top: 0.75em;
  }

  .next {
    text-align: right;
  }
}
