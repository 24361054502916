.register {
  @extend .container
}

.auth-form,
.narrow-form,
.wide-form {
  @include span-columns(4);
  margin-bottom: 6em;
  padding: 1em;

  md-input-container:not([flex]) {
    width: 100%;
  }

  p {
    margin-bottom: 2em;
  }
}

.auth-form,
.narrow-form {
  @include media($desktop) {
    @include span-columns(6);
    @include shift(3);
  }
}

.wide-form {
  @include media($desktop) {
    @include span-columns(8);
    @include shift(2);
  }
}

.login-errors {
  @extend %errors-container;
}
