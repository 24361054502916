$header-height: 100px;
$footer-height: 2rem;


%success-container,
%errors-container {
  background-color: rgba($C_error, 0.8);
  color: $c-light-text;
  padding: 0.5em;
  font-weight: bold;
  margin: 1em 0 2em;
  border-radius: 0.2em;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

%errors-container {
  background-color: rgba($C_error, 0.8);
  color: $c-light-text;
}

%success-container {
  background-color: rgba($C_success, 0.8);
  color: $c-light-text;
}

main {
  height: calc(100vh - #{$header-height} - #{$footer-height});
}

// Generic container class with maximum width. All textual content should live
// within a container with a maximum width.
.container {
  @include outer-container(1400px);
}

// Generic narrow container for containing forms, etc.
.narrow-container {
  @include outer-container(840px);
}

// Wide container that stretches the width of the screen. These can be used for
// background color blocks or dividers.
.wide-container {
  @include outer-container(100%);
}

// Generic content and sidebar containers with a bit of left and right padding
// (for mobile size).
.content,
.content-with-sidebar,
.sidebar {
  padding: 0 1em;
}

// Applies a contrasting background color to some container element.
.inverse {
  color: white;
  background-color: $C500;
}

// Content features row used on homepage.
.features-row {
  padding: 1em;
}

// Desktop-specific styling (in the "mobile-first" direction).
@include media($desktop) {

  .content-with-sidebar,
  %content-with-sidebar {
    @include span-columns(8);
    padding: 0 0 0 1em;
  }

  .content-with-sidebar.push {
    @include shift(4);
    padding: 0 1em 0 0;
  }

  .sidebar.left {
    @include shift(-12);
  }

  .sidebar,
  %sidebar {
    @include span-columns(4);
    padding: 0 1em 0 0;
  }

  .features-container {
    @include span-columns(12);
    //@include shift(2);
  }

  // Pull-over ad-container for desktop size. (This allows the ad to display in
  // the proper vertical position on mobile devices.)
  .features-row.right > .container {
    /*.features-container {
      @include shift(2);
    }*/

    .ad-container.tall {
      @include shift(-12);
      padding: 1em;
    }
  }
}
