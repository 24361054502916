footer {
  $l-nav-heading-font-size: 1.5em;

  padding: 0 1em;
  height: $footer-height;

  .nav-column > a {
    @include black-font;
    text-transform: uppercase;
    font-size: $l-nav-heading-font-size;
  }

  li a {
    font-size: 0.9em;
    @include medium-font;
    color: rgba(white, 0.8);
  }

  ul {
    margin: 0 0 1em 0;
    padding: 0;
    list-style: none;
  }

  ul.no-heading {
    margin-top: $l-nav-heading-font-size;
  }

  .legal {
    font-size: 0.8em;
    opacity: 0.6;
    margin: 0;
    @include transition(all 300ms);

    &:hover {
      opacity: 0.8;
    }

    .separator {
      display: none;
    }

    #copyright, #website-by {
      display: block;
    }

    @include media($desktop) {
      .separator {
        display: inline;
      }

      #copyright, #website-by {
        display: inline;
      }
    }
  }

  @include media($desktop) {
    .nav-column {
      @include span-columns(1 of 5)
    }
  }
}