.form-errors {
  @extend %errors-container;

  a {
    color: white !important;
    text-decoration: underline;
  }
}

.form-success {
  @extend %success-container;
}

.inline-form {
  display: inline-block;
}
