%drawer-nav {
  text-align: center;

  ul {
    margin: 0;
    padding: 0;
  }
}

.md-toolbar-tools a:hover {
  color: inherit !important;
}
