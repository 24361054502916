$line-height: 1.5;

body {
  line-height: $line-height;
}

h1, h2, h3, h4, h5, h6 {
  @include bold-font;
  color: $C500;
  margin: modular-scale(1) 0 modular-scale(-3);
}

.inverse {
  h1, h2, h3, h4, h5, h6 {
    color:$c-light-text;
  }
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 2em;
}

.subdued {
  color: #888;
}