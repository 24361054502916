.widget-wrapper {
  @include display(flex);
  @include align-items(center);
  @include justify-content(center);

  background-color: transparent !important;
  margin-top: 10px !important;
  max-width: 100vw;
}

main.widget {
  @include flex(1);
  padding: 0;
  overflow-x: hidden;
  max-width: 500px;
  min-height: 0;
  height: auto;
  box-shadow: 0 0 8px rgba(black, 0.3);
  background-color: white;

  md-input-container {
    width: 100%;
  }

  md-input-container:not(.notes) {
    height: 54px;
  }

  md-input-container.notes textarea {
    height: 70px !important;
  }

  h1 {
    font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    color: #28428E;
    text-align: center;
    margin-top: 14px;
    padding-bottom: 14px;
    margin-bottom: 0;
    font-size: 30px;
    border-bottom: 1px solid #ddd;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .widget-panes {
    @include display(flex);
    @include flex-direction(row);
    @include transition(left 500ms);

    left: 0;
    width: 400%;
    position: relative;
  }

  .widget-pane {
    @include flex(1);
    padding: 1em;
  }

  .payment-display {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    font-weight: bold;

    .payment-amount {
      text-align: center;
      background: #eee;
      padding: 6px;
      font-size: 18px;
      color: #397cbf;
    }
  }
}