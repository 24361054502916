@mixin black-font {
  font-family: "Encode Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 900;
}

@mixin light-font {
  font-family: "Encode Sans Compressed", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

@mixin medium-font {
  font-family: "Encode Sans Compressed", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

@mixin regular-font {
  font-family: "Encode Sans Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

@mixin semi-bold-font {
  font-family: "Encode Sans Compressed", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

@mixin bold-font {
  @include semi-bold-font;
}