md-dialog {
  padding: 1em;
  min-width: 400px;
  max-width: 100%;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}