#squares {
  padding: 1em;
}

.channel,
.square {
  .heading {
    .main-heading, .sub-heading {
      display: inline-block;
      line-height: 1em;
      margin-bottom: 0;
    }

    .main-heading {
      font-size: 3em;
      color: white;
      text-transform: uppercase;
      @include semi-bold-font;
      margin-bottom: 2rem;
      margin-top: 2rem;
      padding: 0 2px;
    }

    .sub-heading {
      font-size: 0.9em;
    }

    @include media($desktop) {
      .main-heading {
        font-size: 5em;
      }
    }
  }

  &.do {
    .heading .main-heading {
      background-color: $C_do;
    }
    .heading .sub-heading {
      color: $C_do;
    }
  }

  &.eat {
    .heading .main-heading {
      background-color: $C_eat;
    }
    .heading .sub-heading {
      color: $C_eat;
    }
  }

  &.rest {
    .heading .main-heading {
      background-color: $C_rest;
    }
    .heading .sub-heading {
      color: $C_rest;
    }
  }

  &.focus {
    .heading .main-heading {
      background-color: $C_focus;
    }
    .heading .sub-heading {
      color: $C_focus;
    }
  }
}

.channel .channel-background {
  position: relative;

  .heading {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }

  .sub-heading {
    border-bottom: none;
    padding: 0.20em;
    background: rgba(white, 0.7);
    font-size: 1.5em;
  }
}

.square {
  border-bottom: 3px solid rgba($C500, 0.2);

  @include media($desktop) {
    @include span-columns(6, $display: block-collapse);

    &:first-child {
      padding-right: 1em;
    }

    &:last-child {
      border-left: 3px solid rgba($C500, 0.2);
      padding-left: 1em;
    }
  }
}

.features-container .ad-container {
  text-align: center;
}

.separator.diagonal {
  color: white;
  padding: 2em 1em;
  background-color: $C500;

  .fact-heading, .tagline {
    color: $CA700;
    font-family: "Merriweather", serif;
    font-style: italic;
    font-size: 1.4em;
  }

  .fact-content {
    @include light-font;
  }

  .tagline {
    padding-top: 1em;
  }

  @include media($desktop) {
    background-image: url('../images/diagonal-bg.gif');
    background-position: center center;
    max-height: 150px;

    .fact-container, .tagline {
      @include span-columns(5);
    }

    .tagline {
      @include shift(2);
      padding-top: 2em;
    }
  }
}

@include media($desktop) {
  #squares {
    padding: 0 1em;
  }

  #squares .row.bottom .square {
    border-bottom: none;
  }

  .features-col {
    @include span-columns(6);
  }

  .features-row .ad-container.tall {
    padding-top: 2em;
    @include span-columns(4);
  }

  .features-row .ad-container.wide {
    padding-top: 1em;
  }
}
