#header-bar {
  height: $header-height;
  text-align: left;
  width: 100%;
  position: relative;
  z-index: 100;

  &.do {
    border-bottom-color: $C_do;
  }

  &.eat {
    border-bottom-color: $C_eat;
  }

  &.rest {
    border-bottom-color: $C_rest;
  }

  &.focus {
    border-bottom-color: $C_focus;
  }
}

#banner-container #header-bar {
  border-bottom: none;

  @include media($desktop) {
    position: absolute;
  }
}

#header-logo {
  padding: 0.5em;
  max-width: 70%;
  vertical-align: top;
}

#primary-nav, #secondary-nav {
  display: none;

  @include media($desktop) {
    display: inline-block;
  }
}

#primary-nav, #secondary-nav {
  ul {
    margin: 0;
    padding: 0;
  }
}

#primary-nav {
  li {
    display: inline-block;
  }

  a {
    @include semi-bold-font;
    text-transform: uppercase;
    color: $C800;
  }

  @include media($desktop) {
    font-size: 2.4em;
    padding-top: 0.55em;

    li {
      margin-left: 1em;
    }
  }
}

#secondary-nav {
  li {
    display: inline-block;
    vertical-align: middle;
  }

  i {
    padding: 3px 0.2em 0 0;
    font-size: 2em;
    color: $C500;
  }

  @include media($desktop) {
    float: right;
    margin-top: 1.65em;
  }
}

#banner-container {
  #header-bar {
    background: rgba(white, 0.8);
  }
}

#mobile-nav-button {
  font-size: 2.5em;
  display: inline-block;
  margin-top: 0.45em;
  margin-right: 0.5em;

  @include media($desktop) {
    display: none;
  }
}

#mobile-nav-drawer {
  @extend %drawer-nav;
  position: fixed;

  @include media($desktop) {
    display: none;
  }
}

.logout-link {
  font-size: 0.9em;
  float: right;
  display: inline-block;
  padding-right: 0.5em;
  color: #888;

  &:not(:first-child) {
    padding-right: 0.3em;
  }

  &:not(:first-child)::after {
    content: ' | ';
  }
}
