$C50: #8cc6ff;
$C100: #4ca6ff;
$C200: #4ca6ff;
$C300: #4698ea;
$C400: #3f8ad4;
$C500: #397cbf;
$C600: #196cbf;
$C700: #055fa9;
$C800: #04477e;
$C900: #022f53;
$CA100: #f9edb3;
$CA200: #f9da9f;
$CA400: #f9c460;
$CA700: #f9b636;
$C_do: #a72a22;
$C_eat: #39b14a;
$C_rest: #074e8a;
$C_focus: #dba130;
$C_faint: #efefef;

$c-dark-text: #000000;
$c-light-text: #ffffff;

$C_error: #c2061f;
$C_success: #36b205;
